body {
    overflow-y: scroll;
    min-height: 100%;
    font-family: 'Arial', sans-serif;
 
}

    
    body::-webkit-scrollbar-thumb {
        display: none;
    }

    body::-webkit-scrollbar-track {
        display: none;
    }
article {
    font-size: 18px;
}
h6 {
    font-size: 18px;
}