.custom-list {
    list-style: none;
    text-align: left;
    padding: 0px !important;
}

    .custom-list > li::before {
       
        text-align:left;
        font-weight: bold;
    }

.sub-list {
    font-size: 24px;
    counter-reset: sub-counter;
    padding: 0px !important;
}

    .sub-list > li::before {
        content: "✓ ";
        counter-increment: sub-counter;
    }


.card .list-group,
.card .list-group-item {
    border: none;
    font-size: 24px;
}

.custom-group {
    list-style: none;
    padding: 0;
}

    .custom-group li {
        padding: 6px;
        margin-bottom: 8px;
      
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
    }

        .custom-group li:last-child {
            margin-bottom: 0;
        }

.custom-container {
    display: flex;
    justify-content: center;
    align-items: center;}

.dark-background {
    background-color: #343a40; }


@media (min-width: 768px) {
    .role {
        min-width: 470px;
        height: 705px;
    }
}

@media (max-width: 768px) {
    .role {
        max-width: 350px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
    padding: 16px;
}


@media (min-width: 991px) and (max-width: 1191px) {
    font-size: 18px;
    padding: 18px;
}



@media (max-width: 768px) {
    .responsive-address {
       font-size: 18px !important;
    }
  
}



@media only screen and (max-width: 300px) {

    .flag  {
        display: none;
    }
    .hide-logo {
        display: none;
    }
    .usa {
        margin-top: 27px !important;
        margin-left: -111px !important;
        margin-right: 71px !important;
        display: inline-block;
        height: fit-content;
        width: fit-content;
    }

}